import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axiosApi from '../api/apiAxios';
import { ClipLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const generateRandomProjectId = () => {
    return Math.floor(Math.random() * 1000000);
};


const getDefaultSteps = (providerName) => {
    switch (providerName) {
        case 'AWS':
            return [
                {
                    title: "Configure Network on AWS",
                    details: "Default details about configuring the network on AWS...",
                    script: `# AWS Network configuration script\naws ec2 create-vpc --cidr-block 10.0.0.0/16\naws ec2 create-subnet --vpc-id <vpc-id> --cidr-block 10.0.1.0/24`
                },
                {
                    title: "Set Up VMs on AWS",
                    details: "Default details about setting up virtual machines on AWS...",
                    script: `# AWS VM setup script\naws ec2 run-instances --image-id ami-0abcdef1234567890 --count 1 --instance-type t2.micro --key-name MyKeyPair --security-group-ids <sg-id> --subnet-id <subnet-id>`
                },
                {
                    title: "Configure Database on AWS",
                    details: "Default details about configuring the database on AWS...",
                    script: `# AWS RDS configuration script\naws rds create-db-instance --db-instance-identifier mydbinstance --db-instance-class db.t2.micro --engine mysql --master-username admin --master-user-password password --allocated-storage 20`
                },
                {
                    title: "Finalize Deployment on AWS",
                    details: "Final steps for deployment on AWS...",
                    script: `# Finalize deployment script\naws ec2 create-tags --resources <instance-id> --tags Key=Name,Value=MyServer`
                }
            ];
        case 'Google Cloud':
            return [
                {
                    title: "Configure Network on Google Cloud",
                    details: "Default details about configuring the network on Google Cloud...",
                    script: `# Google Cloud Network configuration script\ngcloud compute networks create my-network --subnet-mode=custom\ngcloud compute networks subnets create my-subnet --network=my-network --range=10.0.0.0/24`
                },
                {
                    title: "Set Up VMs on Google Cloud",
                    details: "Default details about setting up virtual machines on Google Cloud...",
                    script: `# Google Cloud VM setup script\ngcloud compute instances create my-instance --zone=us-central1-a --machine-type=n1-standard-1 --subnet=my-subnet`
                },
                {
                    title: "Configure Database on Google Cloud",
                    details: "Default details about configuring the database on Google Cloud...",
                    script: `# Google Cloud SQL configuration script\ngcloud sql instances create my-instance --tier=db-f1-micro --region=us-central1\ngcloud sql users set-password root --host=% --instance=my-instance --password=password`
                },
                {
                    title: "Finalize Deployment on Google Cloud",
                    details: "Final steps for deployment on Google Cloud...",
                    script: `# Finalize deployment script\ngcloud compute instances add-tags my-instance --tags http-server`
                }
            ];
        case 'Azure':
            return [
                {
                    title: "Configure Network on Azure",
                    details: "Default details about configuring the network on Azure...",
                    script: `# Azure Network configuration script\naz network vnet create --name myVnet --resource-group myResourceGroup --subnet-name mySubnet`
                },
                {
                    title: "Set Up VMs on Azure",
                    details: "Default details about setting up virtual machines on Azure...",
                    script: `# Azure VM setup script\naz vm create --resource-group myResourceGroup --name myVM --image UbuntuLTS --vnet-name myVnet --subnet mySubnet --admin-username azureuser --generate-ssh-keys`
                },
                {
                    title: "Configure Database on Azure",
                    details: "Default details about configuring the database on Azure...",
                    script: `# Azure SQL configuration script\naz sql server create --name mydemoserver --resource-group myResourceGroup --location westus --admin-user myadmin --admin-password password`
                },
                {
                    title: "Finalize Deployment on Azure",
                    details: "Final steps for deployment on Azure...",
                    script: `# Finalize deployment script\naz vm open-port --port 80 --resource-group myResourceGroup --name myVM`
                }
            ];
        default:
            return [
                {
                    title: "Generic Deployment Step",
                    details: "This is a generic deployment step in case of an unknown provider.",
                    script: "# Generic deployment script\necho 'Performing generic deployment steps...'"
                }
            ];
    }
};
const DeploymentStep = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const providerData = location.state?.selectedProvider;
    const projectData = location.state?.projectData;

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [steps, setSteps] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDeploymentSteps = async () => {
            try {
                setLoading(true);

                const projectId = generateRandomProjectId();
                const response = await axiosApi.get(`/getChatByProjectId?projectId=${projectData.id}`);
                const chatData = response.data.data;
                const conversation = chatData.history.map(entry => `${entry.role === 'assistant' ? 'Assistant' : 'User'}: ${entry.content}`).join('\n');

                const prompt = `
Project Details:
- Name: ${projectData.name}
- Platform: ${projectData.platform}
- Budget: ${projectData.budget}
- Compliance: ${projectData.compliance}
- Performance: ${projectData.performance || 'N/A'}
- Resource Summary: ${projectData.resource_summary || 'N/A'}

User's recent conversation:
${conversation}
                `;

                const aiResponse = await axiosApi.post('/chatCompletion', {
                    prompt: prompt,
                    system_prompt: systemPrompt,
                    projectId: projectId,
                });

                const generatedSteps = parseDeploymentSteps(aiResponse.data.data);
                setSteps(generatedSteps);
            } catch (error) {

                console.error('Error generating deployment steps:', error);
                setSteps(getDefaultSteps(providerData.name));
            } finally {
                setLoading(false);
            }
        };

        fetchDeploymentSteps();
    }, [projectData, providerData]);

    const parseDeploymentSteps = (text) => {
        try {
            const directJson = JSON.parse(text);
            if (!Array.isArray(directJson)) {
                return [directJson];
            }
            return directJson;
        } catch (e) {
            const jsonMatch = text.match(/{[\s\S]*}/);
            if (jsonMatch) {
                try {
                    const parsedObject = JSON.parse(jsonMatch[0]);
                    return Array.isArray(parsedObject) ? parsedObject : [parsedObject];
                } catch (e) {
                    console.error("Error parsing matched JSON object:", e);
                    return null;
                }
            }
        }
        return null;
    };

    const currentStep = steps[currentStepIndex];

    const handleNext = () => {
        if (currentStepIndex < steps.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
        } else {
            navigate(`/project-summary/${projectData.id}`);
        }
    };

    const handlePrevious = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text("Step-by-Step Deployment Guide", 14, 22);

        const projectDetails = `
Name: ${projectData.name}
Platform: ${projectData.platform}\n\n

        `;
        doc.setFontSize(12);
        doc.text("Project Details", 14, 30);
        doc.text(projectDetails, 14, 36);

        steps.forEach((step, index) => {
            autoTable(doc, {
                startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 10 : 50,
                head: [[`Step ${index + 1}: ${step.title}`]],
                body: [
                    [{ content: step.details, styles: { fontStyle: 'italic' } }],
                    [{ content: `Script:`, styles: { textColor: [0, 0, 200], fontStyle: 'bold' } }],
                    [{ content: step.script || 'No script provided', styles: { font: 'courier', fontSize: 10, textColor: [0, 150, 0] } }],
                ],
                theme: 'striped',
                headStyles: { fillColor: [0, 57, 107] },
                alternateRowStyles: { fillColor: [240, 240, 240] },
            });
        });

        doc.save('deployment_steps.pdf');
    };

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <ClipLoader color="#3B82F6" size={75} />
                <h2 className="text-xl font-semibold text-gray-700">Generating Deployment Steps...</h2>
            </div>
        );
    }

    if (!currentStep) {
        return <div className="text-center text-red-500">No deployment steps available.</div>;
    }

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-50 to-blue-100 p-4">
            <div id="pdf-content" className="w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">Step-by-Step Deployment Guide</h2>
                <div className="bg-gray-50 p-6 rounded-lg shadow-inner mb-6">
                    <div className="flex items-center mb-4">
                        <div className="flex-shrink-0 bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center text-lg font-bold">
                            {currentStepIndex + 1}
                        </div>
                        <h3 className="ml-4 text-xl font-semibold text-gray-700">{currentStep.title}</h3>
                    </div>
                    <div className="ml-14">
                        <p className="text-gray-600 font-semibold mb-4">
                            {currentStep.details}
                        </p>
                        {currentStep.script && (
                            <div className="mt-6 bg-gray-900 text-green-400 p-4 rounded-lg shadow-inner overflow-x-auto">
                                <h4 className="text-lg font-semibold text-gray-300 mb-2">Deployment Script</h4>
                                <pre className="text-sm">
                                    {currentStep.script}
                                </pre>
                                <button
                                    onClick={() => navigator.clipboard.writeText(currentStep.script)}
                                    className="mt-2 bg-blue-500 text-white py-1 px-3 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105">
                                    Copy Script
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrevious}
                        disabled={currentStepIndex === 0}
                        className={`bg-blue-500 text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105 ${currentStepIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        Previous Step
                    </button>
                    <button
                        onClick={handleNext}
                        className={`bg-blue-500 text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105`}>
                        {(currentStepIndex === steps.length - 1) ? "View Summary" : "Next Step"}
                    </button>
                </div>
                <button
                    onClick={exportToPDF}
                    className="mt-6 bg-green-500 text-white py-2 px-5 rounded-lg shadow hover:bg-green-600 transition-transform transform hover:scale-105">
                    Export to PDF
                </button>
            </div>
        </div>
    );
};

export default DeploymentStep;

const systemPrompt = `
You are an intelligent assistant designed to generate step-by-step deployment guides for cloud resources based on user requirements.

Given the project details and user conversation, your task is to generate a deployment guide in the following JSON format. The JSON should include a series of steps where each step has a "title", "details", and "script".

Here is the format you should follow:

[
    {
        "title": "Configure Network on <Provider Name>",
        "details": "Details about configuring the network...",
        "script": "# Network configuration script\\nsudo ip link set eth0 up\\nsudo ip addr add 192.168.1.10/24 dev eth0"
    },
    {
        "title": "Set Up VMs on <Provider Name>",
        "details": "Details about setting up virtual machines...",
        "script": "# VM setup script\\nssh user@hostname 'bash -s' < setup_vm.sh"
    },
    {
        "title": "Configure Database on <Provider Name>",
        "details": "Details about configuring the database...",
        "script": "# Database configuration script\\nsudo mysql_secure_installation"
    },
    {
        "title": "Finalize Deployment on <Provider Name>",
        "details": "Final steps for deployment...",
        "script": "# Finalize deployment script\\nsudo systemctl restart all-services"
    }
]

Ensure that the JSON structure is correct, with double quotes around keys and values, and no additional text or commentary outside of the JSON structure. Replace "<Provider Name>" with the appropriate cloud provider based on the project details.
`;
