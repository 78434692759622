import React, { useState, useCallback, useEffect } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import axiosApi from '../api/apiAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';


const generateRandomProjectId = () => {
    return Math.floor(Math.random() * 1000000);
};

const CloudProviderComparison = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const projectData = location.state?.projectData;
    const [messages, setMessages] = useState([]);
    const [cloudProviders, setCloudProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [warning, setWarning] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Initializing AI...');



    useEffect(() => {

        if (projectData.id !== '0') {
            const fetchHistoryData = async () => {
                try {
                    setLoading(true);
                    const response = await axiosApi.get(`/getChatByProjectId?projectId=${projectData.id}`);
                    const chatData = response.data.data;

                    if (chatData && chatData.history) {
                        const formattedMessages = chatData.history
                            .filter(entry => entry.role !== 'system')
                            .map(entry => ({
                                type: entry.role === 'assistant' ? 'ai' : 'user',
                                text: entry.content,
                            }));
                        setMessages(formattedMessages);
                    }
                } catch (error) {
                    console.error('Error fetching project data:', error);
                }
            };

            fetchHistoryData();
        }
    }, [projectData.id]);

    const extractJson = (text) => {
        try {

            const directJson = JSON.parse(text);


            if (!Array.isArray(directJson)) {
                return [directJson];
            }


            return directJson;
        } catch (e) {

            const jsonMatch = text.match(/{[\s\S]*}/);

            if (jsonMatch) {
                try {

                    const parsedObject = JSON.parse(jsonMatch[0]);


                    return Array.isArray(parsedObject) ? parsedObject : [parsedObject];
                } catch (e) {

                    console.error("Error parsing matched JSON object:", e);
                    return null;
                }
            }
        }

        return null;
    };



    const handleSendMessage = useCallback(async () => {
        setLoading(true);
        setLoadingMessage('Wait, AI is analyzing the project requirements...');
        try {
            const lastMessages = messages.slice(-5).map(msg => `${msg.type === 'user' ? 'User' : 'Assistant'}: ${msg.text}`).join('\n');
            const projectDetails = `
    Project Details:
    Name: ${projectData.name}
    Platform: ${projectData.platform}
    Budget: ${projectData.budget}
    Compliance: ${projectData.compliance}
    Performance: ${projectData.performance ? projectData.performance : 'N/A'}
    Resource Summary: ${projectData.resource_summary ? projectData.resource_summary : 'N/A'}
            `;

            const fullPrompt = `
    ${projectDetails}
    
    User's recent conversation:
    ${lastMessages}
            `;
            const projectId = generateRandomProjectId();

            const aiResponse = await axiosApi.post('/chatCompletion', {
                prompt: fullPrompt,
                system_prompt: systemPrompt,
                projectId: projectId
            });

            setLoadingMessage('Wait, AI is compiling the data...');

            const jsonResponse = extractJson(aiResponse.data.data);
            console.log('AI Response:', jsonResponse);

            if (jsonResponse) {
                setCloudProviders(jsonResponse);
                setLoadingMessage('AI is finalizing the comparison...');
            } else {
                setCloudProviders(demoResponse);
            }

        } catch (error) {
            setCloudProviders(demoResponse);
        } finally {
            setLoading(false);
        }
    }, [messages, projectData]);

    useEffect(() => {
        handleSendMessage();
    }, [handleSendMessage]);

    const exportPDF = () => {
        const doc = new jsPDF();
        doc.text('Cloud Provider Detailed Comparison', 14, 16);
        const columns = ['Criteria', ...cloudProviders.map(provider => provider.name)];
        const rows = Object.keys(cloudProviders[0].details).map(criteria => {
            return [criteria, ...cloudProviders.map(provider => provider.details[criteria])];
        });
        autoTable(doc, {
            startY: 20,
            head: [columns],
            body: rows,
            styles: { fontSize: 8, cellPadding: 2 },
            headStyles: { fillColor: [0, 123, 255] },
        });
        doc.save('cloud-provider-comparison.pdf');
    };

    const handleProviderSelect = () => {
        if (selectedProvider) {
            handleUpdateProject();
            navigate(`/deploy/${selectedProvider.id}`, { state: { selectedProvider, projectData } });
            setWarning('');
        } else {
            setWarning('Please select a provider before proceeding.');
        }
    };



    // save user preferences to put api
    const handleUpdateProject = async () => {
        try {
            await axiosApi.put('/updateProject', {
                id: projectData.id,
                platform: selectedProvider.name,
                resource_summary: {
                    cost: selectedProvider.details.Cost,
                    performance: selectedProvider.details.Performance,
                    vms: "3",
                    databases: "1",
                    storage: "50GB"
                }
            });
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };




    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <ClipLoader color="#3B82F6" size={75} />
                <h2 className="text-xl font-semibold text-gray-700">{loadingMessage}</h2>
            </div>
        );
    }

    return (
        <div className="p-8 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-lg max-w-7xl mx-auto">
            <div className="flex justify-between items-center mb-8">
                <h2 className="text-3xl font-bold text-blue-700">Cloud Provider Detailed Comparison</h2>
                <button
                    onClick={exportPDF}
                    className="bg-blue-500 text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105"
                >
                    Export as PDF
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-auto border-collapse rounded-lg shadow-sm">
                    <thead>
                        <tr className="bg-blue-500 text-white">
                            <th className="p-3 sticky left-0 bg-blue-500 z-10">Criteria</th>
                            {cloudProviders.map(provider => (
                                <th key={provider.name} className="p-3 text-center">
                                    {provider.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(cloudProviders[0].details).map((criteria, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>
                                <td className="p-3 font-semibold sticky left-0 bg-white z-10 text-black">{criteria}</td>
                                {cloudProviders.map(provider => (
                                    <td key={provider.name} className="p-3 text-center text-black">
                                        {provider.details[criteria]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr className="bg-green-100">
                            <td className="p-3 font-semibold sticky left-0 bg-green-100 z-10 text-black">Select</td>
                            {cloudProviders.map(provider => (
                                <td key={provider.name} className="p-3 text-center">
                                    <input
                                        type="radio"
                                        name="cloudProvider"
                                        className="bg-white text-white h-5 w-5 border-gray-300"
                                        value={provider.name}
                                        onChange={() => setSelectedProvider(provider)}
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            {warning && (
                <div className="mt-4 text-red-500 font-semibold">
                    {warning}
                </div>
            )}
            <div className="flex justify-center mt-8">
                <button
                    onClick={handleProviderSelect}
                    className="mr-4 bg-blue-500 text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105"
                >
                    Click to Proceed
                </button>
                <button
                    onClick={() => window.history.back()}
                    className="bg-gray-500 text-white py-2 px-5 rounded-lg shadow hover:bg-gray-600 transition-transform transform hover:scale-105"
                >
                    Go Back
                </button>
            </div>
        </div>
    );
};

export default CloudProviderComparison;



const systemPrompt = `
You are an intelligent assistant designed to help users compare cloud service providers based on their project requirements.:


1. Cloud Provider Comparison:
   - Based on the user's input, generate a comparison table of the selected cloud providers. The comparison should include key metrics all of these , cost, security, performance, compliance, and risk. The data should be formatted as a structured table for easy readability.
   - Example structure:
 
   [
       {
           name: "Google Cloud",
           id: "gcp",
           details: {
               Cost: "$200 - $3,000/month",
               Security: "Advanced security with default encryption and broad compliance certifications.",
               Performance: "High-performance global infrastructure with AI-driven optimizations.",
               Compliance: "GDPR, HIPAA, and other global standards compliant.",
               Risk: "Low risk with strong redundancy and failover."
           }
       },
       {
           name: "AWS",
           id: "aws",
           details: {
               Cost: "$100 - $5,000/month",
               Security: "Comprehensive security including firewalls, encryption, and identity management.",
               Performance: "Excellent global performance with extensive scaling options.",
               Compliance: "Complies with PCI-DSS, HIPAA, FedRAMP, and more.",
               Risk: "Very low risk with a robust global infrastructure."
           }
       },
       {
           name: "Azure",
           id: "azure",
           details: {
               Cost: "$150 - $4,000/month",
               Security: "Strong security integrated with Microsoft’s security suite.",
               Performance: "Great performance, especially in hybrid cloud and Microsoft-centric environments.",
               Compliance: "Extensive compliance coverage for regulated industries.",
               Risk: "Low risk with high availability and disaster recovery."
           }
       }
   ]

Note:Do the comparision between AWS GCP AZURE and if user mentioned any other cloud provider include it also and Dont ask for any more information from user if not provided and only return the JSON Response no other text if user doesnt provide any data generate the data comparision like this above.
`;



const demoResponse = [
    {
        "name": "AWS",
        "id": "aws",
        "details": {
            "Cost": "$100 - $5,000/month",
            "Security": "Comprehensive security including firewalls, encryption, and identity management.",
            "Performance": "Excellent global performance with extensive scaling options.",
            "Compliance": "Complies with PCI-DSS, HIPAA, FedRAMP, and more.",
            "Risk": "Very low risk with a robust global infrastructure."
        }
    },
    {
        "name": "Google Cloud",
        "id": "gcp",
        "details": {
            "Cost": "$200 - $3,000/month",
            "Security": "Advanced security with default encryption and broad compliance certifications.",
            "Performance": "High-performance global infrastructure with AI-driven optimizations.",
            "Compliance": "GDPR, HIPAA, and other global standards compliant.",
            "Risk": "Low risk with strong redundancy and failover."
        }
    },
    {
        "name": "Azure",
        "id": "azure",
        "details": {
            "Cost": "$150 - $4,000/month",
            "Security": "Strong security integrated with Microsoft’s security suite.",
            "Performance": "Great performance, especially in hybrid cloud and Microsoft-centric environments.",
            "Compliance": "Extensive compliance coverage for regulated industries.",
            "Risk": "Low risk with high availability and disaster recovery."
        }
    }
]
