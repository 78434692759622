import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const animatedMessagesMap = new Map();

const AnimatedText = ({ text }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isComplete, setComplete] = useState(false);




    useEffect(() => {
        if (isComplete) return;

        if (animatedMessagesMap.has(text)) {
            setDisplayedText(text);
            setComplete(true);

        } else {
            let currentText = '';
            let index = 0;

            const interval = setInterval(() => {
                const chunk = text.slice(index, index + 5);
                currentText += chunk;
                setDisplayedText(currentText);

                index += 5;

                if (index >= text.length) {
                    clearInterval(interval);
                    setComplete(true);
                    animatedMessagesMap.set(text, true);
                }
            }, 5);

            return () => clearInterval(interval);
        }
    }, [text, isComplete]);

    return (
        <div className="">
            <ReactMarkdown
                className="prose prose-invert"
                children={displayedText}
                remarkPlugins={[remarkGfm]}
            />
        </div>
    );
};

export default AnimatedText;
