import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRocket, FaPlus, FaChevronDown, FaSearch, FaTimes } from 'react-icons/fa';
import axiosApi from '../api/apiAxios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../api/AuthContext';


const CreateProjectModal = ({ isOpen, onClose, initialData, onSubmit }) => {
    const [formData, setFormData] = useState({
        projectName: initialData?.name || '',
        cloudPlatform: initialData?.platform || '',
        resourceNeeds: initialData?.resource_summary || '',
        budgetConstraints: initialData?.budget ? initialData.budget.split(' - ').map(b => b.replace('$', '')) : [1000, 5000],
        securityRequirements: initialData?.compliance || ''
    });
    const [isLoading, setIsLoadingInternal] = useState(false);
    const [selectedTags, setSelectedTags] = useState(initialData?.resource_summary?.split(', ') || []);


    const cloudServices = [
        'S3 Storage',
        'CloudFront CDN',
        'Lambda Functions',
        'API Gateway',
        'DynamoDB',
        'RDS Database',
        'EC2 Instances',
        'Elastic Beanstalk',
        'VPC',
        'CloudWatch',
        'SNS',
        'SQS',
        'IAM Roles'
    ];

    useEffect(() => {
        if (initialData) {
            setFormData({
                projectName: initialData.name,
                cloudPlatform: initialData.platform,
                resourceNeeds: initialData.resource_summary,
                budgetConstraints: initialData.budget ? initialData.budget.split(' - ').map(b => b.replace('$', '')) : [1000, 5000],
                securityRequirements: initialData.compliance
            });
            setSelectedTags(initialData.resource_summary?.split(', ') || []);
        }
    }, [initialData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleTagClick = (tag) => {
        if (!selectedTags.includes(tag)) {
            setSelectedTags([...selectedTags, tag]);
            setFormData({ ...formData, resourceNeeds: [...selectedTags, tag].join(', ') });
        }
    };

    const handleRemoveTag = (tag) => {
        const newTags = selectedTags.filter(t => t !== tag);
        setSelectedTags(newTags);
        setFormData({ ...formData, resourceNeeds: newTags.join(', ') });
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value.trim() !== '') {
            handleTagClick(e.target.value.trim());
            e.target.value = '';
        }
    };

    const handleSliderChange = (value) => {
        setFormData({ ...formData, budgetConstraints: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingInternal(true);

        try {
            await onSubmit({
                name: formData.projectName,
                platform: formData.cloudPlatform || '',
                budget: `$${formData.budgetConstraints[0]} - $${formData.budgetConstraints[1]}`,
                compliance: formData.securityRequirements || '',
                resource_summary: formData.resourceNeeds || '',
            });
        } catch (error) {
            console.error('Error submitting project:', error);
        } finally {
            setIsLoadingInternal(false);
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-80">
            <div className="bg-gray-100 rounded-lg shadow-xl w-full max-w-lg p-8 relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 transition-colors"
                >
                    <FaTimes size={24} />
                </button>
                <h2 className="text-2xl font-bold mb-6 text-gray-900">{initialData ? 'Edit Project' : 'Create a New Project'}</h2>
                <form onSubmit={handleSubmit} className="space-y-5">
                    <div>
                        <label className="block text-xs font-medium mb-1 text-gray-900">Project Name</label>
                        <input
                            type="text"
                            name="projectName"
                            value={formData.projectName}
                            onChange={handleChange}
                            className="w-full px-2 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white text-black"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-xs font-medium mb-1 text-gray-900">Preferred Cloud Platform</label>
                        <select
                            name="cloudPlatform"
                            value={formData.cloudPlatform}
                            onChange={handleChange}
                            className="w-full px-2 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white text-black"
                        >
                            <option value="">Select a cloud platform</option>
                            <option value="aws">AWS</option>
                            <option value="azure">Azure</option>
                            <option value="gcp">Google Cloud Platform</option>
                            <option value="ibm">IBM Cloud</option>
                            <option value="oracle">Oracle Cloud</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-xs font-medium mb-1 text-gray-900">Resource Needs</label>
                        <div className="flex flex-wrap gap-2 mb-2">
                            {selectedTags.map((tag, index) => (
                                <span key={index} className="bg-blue-100 text-blue-700 px-2 py-1 rounded-full flex items-center text-xs">
                                    {tag}
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveTag(tag)}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                    >
                                        <FaTimes size={10} />
                                    </button>
                                </span>
                            ))}
                        </div>
                        <input
                            type="text"
                            placeholder="Add a resource..."
                            onKeyDown={handleInputKeyDown}
                            className="text-black w-full px-2 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white"
                        />
                        <div className="flex flex-wrap gap-2 mt-2">
                            {cloudServices.map((service, index) => (
                                <button
                                    type="button"
                                    key={index}
                                    onClick={() => handleTagClick(service)}
                                    className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full hover:bg-gray-300 text-xs"
                                >
                                    {service}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div>
                        <label className="block text-xs font-medium mb-1 text-gray-900">Monthly Budget Constraints</label>
                        <div className="flex items-center">
                            <span className="text-sm text-gray-700 mr-3">${formData.budgetConstraints[0]}</span>
                            <Slider
                                range
                                min={100}
                                max={10000}
                                defaultValue={formData.budgetConstraints}
                                onChange={handleSliderChange}
                                trackStyle={[{ backgroundColor: '#2091DC' }]}
                                handleStyle={[
                                    { borderColor: '#2091DC' },
                                    { borderColor: '#2091DC' }
                                ]}
                                railStyle={{ backgroundColor: '#ddd' }}
                            />
                            <span className="text-sm text-gray-700 ml-3">${formData.budgetConstraints[1]}</span>
                        </div>
                    </div>
                    <div>
                        <label className="block text-xs font-medium mb-1 text-gray-900">Security Requirements</label>
                        <textarea
                            name="securityRequirements"
                            value={formData.securityRequirements}
                            onChange={handleChange}
                            className="text-black w-full px-2 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white"
                            rows="3"
                        ></textarea>
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors text-sm"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`px-4 py-2 rounded-md text-white ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90'
                                } bg-gradient-to-r from-[#16B197] to-[#2091DC] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors text-sm`}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateProjectModal;
