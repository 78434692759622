import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import axiosApi from '../api/apiAxios';
import { useNavigate, useParams } from 'react-router-dom';
import CreateProjectModal from './CreateProjectModal';

const ProjectSummary = () => {
    const [projectSummary, setProjectSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const openEditModal = () => {
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    useEffect(() => {
        const fetchProjectSummary = async () => {
            try {
                setLoading(true);

                const projectId = params.id;


                const projectResponse = await axiosApi.get(`/getProjectById?id=${projectId}`);
                const projectData = projectResponse.data.data;






                console.log(projectData);
                const summaryData = {
                    projectName: projectData.name,
                    cloudProvider: projectData.platform,
                    status: "Active",
                    resources: {
                        vms: projectData.resource_summary.vms,
                        databases: projectData.resource_summary.databases,
                        storage: projectData.resource_summary.storage,
                    },
                    cost: projectData.resource_summary.cost,
                    performance: projectData.resource_summary.performance,
                };

                setProjectSummary(summaryData);
            } catch (error) {
                console.error('Error fetching project summary:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjectSummary();
    }, []);



    const handleUpdateProject = async (updatedData) => {
        try {
            await axiosApi.put('/updateProject', {
                id: params.id,
                ...updatedData
            });
            setProjectData({ ...projectData, ...updatedData });
            setIsEditModalOpen(false);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <ClipLoader color="#3B82F6" size={75} />
                <h2 className="text-xl font-semibold text-gray-700">Loading Project Summary...</h2>
            </div>
        );
    }

    if (!projectSummary) {
        return <div className="text-center text-red-500">Failed to load project summary.</div>;
    }


    // handle navigation to the dashboard
    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };

    // view logs
    const viewLogs = () => {
        navigate(`/project/${params.id}`);
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-50 to-blue-100 p-4">
            <div className="w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg">
                <h2 className="text-xl py-2 font-bold bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white mb-6 text-center rounded-lg">Project Summary</h2>

                <div className="bg-gray-50 p-4 rounded-lg shadow-inner mb-6">
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">Project Overview</h3>
                    <p className="text-gray-600">
                        <strong>Project Name:</strong> {projectSummary.projectName}<br />
                        <strong>Cloud Provider:</strong> {projectSummary.cloudProvider}<br />
                        <strong>Status:</strong> {projectSummary.status}
                    </p>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg shadow-inner mb-6">
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">Resource Summary</h3>
                    <p className="text-gray-600">
                        <strong>VMs:</strong> {projectSummary.resources.vms}<br />
                        <strong>Databases:</strong> {projectSummary.resources.databases}<br />
                        <strong>Storage:</strong> {projectSummary.resources.storage}
                    </p>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg shadow-inner mb-6">
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">Cost & Performance</h3>
                    <p className="text-gray-600">
                        <strong>Monthly Cost:</strong> {projectSummary.cost}<br />
                        <strong>Performance:</strong> {projectSummary.performance}
                    </p>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg shadow-inner mb-6">

                    <div className="grid grid-cols-2 gap-4">
                        <button onClick={viewLogs} className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105">
                            View Logs
                        </button>
                        <button onClick={openEditModal} className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105">
                            Modify Resources
                        </button>

                    </div>
                    <button onClick={handleGoToDashboard} className="mt-4 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 transition-transform transform hover:scale-105 w-full">
                        Go to Dashboard
                    </button>
                </div>
            </div>
            {isEditModalOpen && (
                <CreateProjectModal
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    initialData={projectData}
                    onSubmit={handleUpdateProject}
                />
            )}
        </div>
    );
};

export default ProjectSummary;
