import ButtonGradient from "../../assets/svg/ButtonGradient";
import Collaboration from "../../components/Collaboration";
import React from "react";
import Services from "../../components/Services";
import Pricing from "../../components/Pricing";
import Roadmap from "../../components/Roadmap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Benefits from "../../components/Benefits";


const LandingPage = () => {
    return (
        <>
            <div className="overflow-hidden">
                <Header />
                <Hero />
                <Benefits />
                <Collaboration />
                <Services />
                <Pricing />
                <Roadmap />
                <Footer />
            </div>
            <ButtonGradient />
        </>
    );
};

export default LandingPage;
